import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import ProblemScapeGameHeader from "~/components/ProblemScapeGameHeader";
import Seo from "~/components/Seo";

function ProblemScapeCh2Page() {
  return (
    <>
      <ProblemScapeGameHeader />
      <section
        id="chapter-2-overview"
        className="Index-page"
        data-collection-id="6063ac0744f4620bf8c1a625"
        data-edit-main-image="Background"
      >
        <div
          className="Index-page-content "
          id="yui_3_17_2_1_1671304180297_2729"
        >
          <div
            className="sqs-layout sqs-grid-12 columns-12"
            data-type="page"
            id="page-6063ac0744f4620bf8c1a625"
          >
            <div className="row sqs-row" id="yui_3_17_2_1_1671304180297_2728">
              <div
                className="col sqs-col-12"
                id="yui_3_17_2_1_1671304180297_2727"
              >
                <div
                  className="sqs-block html-block sqs-block-html"
                  data-block-type="2"
                  id="block-7e66dcad8ac3d97511f9"
                >
                  <div className="sqs-block-content">
                    <h2 style={{ textAlign: "center", whiteSpace: "pre-wrap" }}>
                      CHAPTER 2 OVERVIEW:
                      <br />
                      ALL MINE!
                    </h2>
                  </div>
                </div>
                <div
                  className="row sqs-row"
                  id="yui_3_17_2_1_1671304180297_2726"
                >
                  <div
                    className="col sqs-col-6 span-6"
                    id="yui_3_17_2_1_1671304180297_2725"
                  >
                    <div
                      className="sqs-block image-block sqs-block-image sqs-text-ready"
                      data-aspect-ratio="80.67542213883677"
                      data-block-type="5"
                      id="block-0cf43b4c8e9f2fdc0b4a"
                    >
                      <div
                        className="sqs-block-content"
                        id="yui_3_17_2_1_1671304180297_2724"
                      >
                        <div
                          className="
          image-block-outer-wrapper
          layout-caption-below
          design-layout-inline
          combination-animation-none
          individual-animation-none
          individual-text-animation-none
        "
                          data-test="image-block-inline-outer-wrapper"
                          id="yui_3_17_2_1_1671304180297_2723"
                        >
                          <StaticImage
                            src="../../images/problemscape/ch2-1.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col sqs-col-6 span-6"
                    id="yui_3_17_2_1_1671304180297_2750"
                  >
                    <div
                      className="sqs-block image-block sqs-block-image sqs-text-ready"
                      data-aspect-ratio="80.30018761726079"
                      data-block-type="5"
                      id="block-yui_3_17_2_1_1658170732977_7726"
                    >
                      <div
                        className="sqs-block-content"
                        id="yui_3_17_2_1_1671304180297_2749"
                      >
                        <div
                          className="
          image-block-outer-wrapper
          layout-caption-below
          design-layout-inline
          combination-animation-none
          individual-animation-none
          individual-text-animation-none
        "
                          data-test="image-block-inline-outer-wrapper"
                          id="yui_3_17_2_1_1671304180297_2748"
                        >
                          <StaticImage
                            src="../../images/standards/chapter-2.png"
                            height={400}
                            alt="Chapter 2"
                            width={533}
                            objectFit="cover"
                            transformOptions={{
                              fit: "cover",
                              cropFocus: "bottom",
                            }}
                            quality={95}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="sqs-block html-block sqs-block-html"
                  data-block-type="2"
                  id="block-1ad1c3feeac649ddb353"
                >
                  <div className="sqs-block-content">
                    <h3 style={{ whiteSpace: "pre-wrap" }}>
                      <strong>Enter the mining village</strong>
                    </h3>
                    <ul data-rte-list="default">
                      <li>
                        <p style={{ whiteSpace: "pre-wrap" }}>
                          In chapter 2, the player discovers that the mining
                          shopkeeper and the banker have gone missing. They need
                          to help the shopkeeper’s cousin to convert gems, and
                          the janitor who is minding the bank, to exchange gems
                          for the mined ore. The player also gets a pickaxe from
                          the shopkeeper and they get to do some mining for
                          gems.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="row sqs-row"
                  id="yui_3_17_2_1_1671304180297_2770"
                >
                  <div
                    className="col sqs-col-6 span-6"
                    id="yui_3_17_2_1_1671304180297_2769"
                  >
                    <div
                      className="sqs-block image-block sqs-block-image sqs-text-ready"
                      data-block-type="5"
                      id="block-yui_3_17_2_1_1617146437192_25338"
                    >
                      <div
                        className="sqs-block-content"
                        id="yui_3_17_2_1_1671304180297_2768"
                      >
                        <div
                          className="
          image-block-outer-wrapper
          layout-caption-below
          design-layout-inline
          combination-animation-none
          individual-animation-none
          individual-text-animation-none
        "
                          data-test="image-block-inline-outer-wrapper"
                          id="yui_3_17_2_1_1671304180297_2767"
                        >
                          <StaticImage
                            src="../../images/problemscape/cleaner.png"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col sqs-col-6 span-6"
                    id="yui_3_17_2_1_1671304180297_2789"
                  >
                    <div
                      className="sqs-block image-block sqs-block-image sqs-text-ready"
                      data-aspect-ratio="74.85928705440901"
                      data-block-type="5"
                      id="block-d743f6eb4610758647ee"
                    >
                      <div
                        className="sqs-block-content"
                        id="yui_3_17_2_1_1671304180297_2788"
                      >
                        <div
                          className="
          image-block-outer-wrapper
          layout-caption-below
          design-layout-inline
          combination-animation-none
          individual-animation-none
          individual-text-animation-none
        "
                          data-test="image-block-inline-outer-wrapper"
                          id="yui_3_17_2_1_1671304180297_2787"
                        >
                          <StaticImage
                            src="../../images/problemscape/miner.png"
                            alt=""
                            height={400}
                            width={533}
                            transformOptions={{
                              fit: "cover",
                              cropFocus: "center",
                            }}
                            quality={95}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="sqs-block html-block sqs-block-html"
                  data-block-type="2"
                  id="block-2606db09c70eccc2ebac"
                >
                  <div className="sqs-block-content">
                    <h3 style={{ textAlign: "center", whiteSpace: "pre-wrap" }}>
                      <strong>chapter highlight</strong>
                    </h3>
                  </div>
                </div>
                <div
                  className="row sqs-row"
                  id="yui_3_17_2_1_1671304180297_2809"
                >
                  <div
                    className="col sqs-col-6 span-6"
                    id="yui_3_17_2_1_1671304180297_2808"
                  >
                    <div
                      className="sqs-block image-block sqs-block-image sqs-text-ready"
                      data-block-type="5"
                      id="block-46e4c31fbbd2bb62c5ec"
                    >
                      <div
                        className="sqs-block-content"
                        id="yui_3_17_2_1_1671304180297_2807"
                      >
                        <div
                          className="
          image-block-outer-wrapper
          layout-caption-below
          design-layout-inline
          combination-animation-none
          individual-animation-none
          individual-text-animation-none
        "
                          data-test="image-block-inline-outer-wrapper"
                          id="yui_3_17_2_1_1671304180297_2806"
                        >
                          <StaticImage
                            src="../../images/problemscape/teachpad-2.png"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col sqs-col-6 span-6">
                    <div
                      className="sqs-block spacer-block sqs-block-spacer sized vsize-1"
                      data-block-type="21"
                      id="block-yui_3_17_2_1_1658165316258_65817"
                    >
                      <div className="sqs-block-content">&nbsp;</div>
                    </div>
                    <div
                      className="sqs-block html-block sqs-block-html"
                      data-block-type="2"
                      id="block-e3244941b1b539c1b1e2"
                    >
                      <div className="sqs-block-content">
                        <h3 style={{ whiteSpace: "pre-wrap" }}>
                          <strong>Teachpad for Variables</strong>
                        </h3>
                        <ul data-rte-list="default">
                          <li>
                            <p style={{ whiteSpace: "pre-wrap" }}>
                              The player has to teach the mining shopkeeper how
                              to convert gems. From thinking about how to arrive
                              at a general instruction from specific examples,
                              students get a deep understanding of what a
                              variable is and how they are useful!
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export function Head() {
  return <Seo title="Ch 2 Overview" />;
}

export default ProblemScapeCh2Page;
